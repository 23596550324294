import React from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";

const Favoritos = () => {
  
    return(
        <Layout>
            <Container>
            <div className="p-5 flex-col text-lg rounded-xl bg-white shadow-lg">
                <div className="flex justify-center font-semibold text-3xl my-4 text-primary-400">
                    Mis favoritos
                </div>
                <div className="flex justify-center text-xl my-4 text-secondary-400">
                    Actualmente no cuentas con ningun favorito
                </div>
            </div>
            </Container>
        </Layout>
      )
  }
    
export default Favoritos;