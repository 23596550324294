import axios from "axios";
// const BASE_URL = "https://localhost:5001/";
// const BASE_URL = "http://192.168.1.79:3201/";
const BASE_URL = "https://api.merent.mx/";

export const merent_instance = () => {
    const token = "";
    return axios.create({
        baseURL:BASE_URL,
        timeout:15000,
    });
};