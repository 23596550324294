import React from "react";
import { MdCardMembership } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";

export const dummy_data_categorias = [
    {
        id: 1,
        value: 1,
        nombreCategoria: "Equipos de construcción",
        label: "Equipos de construcción",
        descripcion: 'Equipos que se rentan para llevar a cabo tareas de construcción',
        keywords: 'construccion, mezcla, albañileria'
    },
    {
        id: 2,
        value: 2,
        nombreCategoria: "Equipos de alturas",
        label: "Equipos de alturas",
        descripcion: 'Equipos que se rentan para llevar a cabo tareas en las alturas',
        keywords: 'alturas andamios cuerda'
    },
    {
        id: 3,
        value: 3,
        nombreCategoria: "Montacargas",
        label: "Montacargas",
        descripcion: 'Equipos para cargar cosas pesadas',
        keywords: 'montacargas carga'
    }
];

export const dummy_data_estados = [
    {"value":"1","label":"Jalisco","abreviatura":"Jal."},
    {"value":"2","label":"Nuevo Leon","abreviatura":"NL"},
    {"value":"3","label":"Baja California Sur","abreviatura":"BCS"},
    {"value":"4","label":"Yucatan","abreviatura":"Yuc."},
    {"value":"5","label":"Ciudad de Mexico","abreviatura":"CDMX"}
];

export const dummy_data_ciudades = [
    {"value":"1","label":"Guadalajara","estado":"1"},
    {"value":"2","label":"Zapopan","estado":"1"},
    {"value":"3","label":"Tlaquepaque","estado":"1"},
    {"value":"4","label":"Monterrey","estado":"2"},
    {"value":"5","label":"Apodaca","estado":"2"},
    {"value":"6","label":"Guadalupe","estado":"2"},
    {"value":"7","label":"Mulegé","estado":"3"},
    {"value":"8","label":"La Paz","estado":"3"},
    {"value":"9","label":"Los Cabos","estado":"3"},
    {"value":"10","label":"Mérida","estado":"4"},
    {"value":"11","label":"Abalá","estado":"4"},
    {"value":"12","label":"Acancéh","estado":"4"},
    {"value":"13","label":"Cuauhtémoc","estado":"5"},
    {"value":"14","label":"Gustavo A. Madero","estado":"5"},
    {"value":"15","label":"Iztacalco","estado":"5"}
];

export const dummy_data_tipos_esquemas = [

    {
        id: 2,
        value: 2,
        nombreEsquema: 'Renta Segura',
        description: "Directamente a través de la aplicación.",
        label: 'Renta Segura',
        icon: <RiSecurePaymentFill/>,
        important_text: 
        <div className="items-center justify-center">
            <p className="text-center text-white font-bold text-xlg">2%</p>
            <p className='text-center text-secondary-400 text-sm'>De comisión por renta</p>
        </div>,
        beneficios:
        <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
            <li>Pago asegurado del flete ida y vuelta </li>
            <li>Asesoría en el proceso de renta </li>
            <li>Historial detallado de rentas </li>
            <li>Alertas de seguimiento automáticas </li>
            <li>Programación calendarizada de rentas </li>
            <li>Opción para ofrecer descuento por equipo </li> 
            <li>Tickets de soporte y su seguimiento automático </li>
            <li>Contrato </li>
            <li>Monitoreo de renta </li>
            <li>Aumentar el tiempo de renta segura </li>
            <li>Promoción SEO de su equipo dentro del Portal e Internet</li>
        </ul>
    },
    {
        id: 1,
        value: 1,
        nombreEsquema: 'Renta Abierta',
        description: "Directamente con el dueño del equipo.",
        label: 'Renta Abierta',
        icon: <MdCardMembership/>,
        important_text: 
        <div>
            <p className="text-center text-white font-bold text-xlg">$99.00</p>
            <p className='text-center text-secondary-400 text-sm'>Mensuales</p>
        </div>,
        beneficios:
        <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
            <li>Promociona tus equipos sin límite</li>
            <li>Paga por mes sin dar comisión por tus rentas</li>
            <li>Trato directo con el cliente</li>
        </ul>
    }
];

export const dummy_data_equipos = [
    {
        id: 1,
        nombreEquipo: "Montacargas Reach Slider 7000 Series",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        precioSemana: 3500.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 2,
        nombreEquipo: "Montacargas Reach Slider 8000 Series",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran tres de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '8000 Series',
        horometro: '1205',
        precioDia: 980.00,
        precioSemana: 4500.00,
        descuento: 2,
        tieneDescuento: 'No',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 3,
        nombreEquipo: "Andamio Amarillo",
        categoriaId: 1,
        categoria: 'Andamio',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        precioSemana: 3500.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 4,
        nombreEquipo: "Montacargas RS 899",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran tres de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '8000 Series',
        horometro: '1205',
        precioDia: 980.00,
        precioSemana: 4500.00,
        descuento: 2,
        tieneDescuento: 'No',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 5,
        nombreEquipo: "Andamio Azul",
        categoriaId: 1,
        categoria: 'Andamio',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        precioSemana: 3500.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 6,
        nombreEquipo: "Montacargas Reach Slider 8000 Series",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran tres de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '8000 Series',
        horometro: '1205',
        precioDia: 980.00,
        precioSemana: 4500.00,
        descuento: 2,
        tieneDescuento: 'No',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 7,
        nombreEquipo: "Montacargas Reach Slider 7000 Series",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        precioSemana: 3500.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 2,
        nombreEsquema: 'Renta Abierta',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 8,
        nombreEquipo: "Montacargas Reach Slider 8000 Series",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran tres de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '8000 Series',
        horometro: '1205',
        precioDia: 980.00,
        precioSemana: 4500.00,
        descuento: 2,
        tieneDescuento: 'No',
        estatus: true,
        esquema: 2,
        nombreEsquema: 'Renta Abierta',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
];

export const dummy_data_equipo = 
    {
        id: 1,
        nombreEquipo: "Montacargas Reach Slider 7000 Series",
        nombreEmpresa: "Montacargas Mty",
        ubicacionEmpresa: "Apodaca, Nuevo León",
        calificacion: 4.5,
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: '780.00',
        precioSemana: '3,500.00',
        precioQuincena: '6,000.00',
        precioMes: '11,000.00',
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 1,
        nombreEsquema:'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        images: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg',
                'https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    };

export const dummy_data_equipos_servicios = [
    {
        id: 1,
        nombreServicio: "Mantenimiento a llantas",
        descripcion: 'Se debe hacer empezando por las delanteras y posteriormente las traseras',
        frecuencia: '7 horas'
    },
    {
        id: 1,
        nombreServicio: "Mantenimiento a pulsometro",
        descripcion: 'Se debe hacer de la forma correcta',
        frecuencia: '7 horas'
    }
];

export const dummy_data_equipos_aditamentos = [
    {
        id: 1,
        nombreAditamento: "Guia Laser",
        descripcion: 'Ayuda en las operaciones para una carga y descarga de tarimas en altura, dando una guía de laser clara en la tarima.',
        precio: 150.00
    },
    {
        id: 1,
        nombreAditamento: "Pantalla con Videocámara",
        descripcion: 'El monitor a color montado en el compartimento del operador ayuda para identificar, poner y tomar tarimas en mayores alturas,.',
        precio: 150.00
    },
    {
        id: 1,
        nombreAditamento: "Sistema thermakit",
        descripcion: 'Para las bodegas frías, nuestra palanca de control y piso con calefacción mantienen a los operadores protegidos del frio. Manteniendo manos y pies cálidos por mas tiempo, ThermaKit brinda productividad mejorada turno tras turno.',
        precio: 150.00
    }
];

export const dummy_data_equipos_a_rentar = [
    {
        id: 1,
        nombreEquipo: "Montacargas Reach Slider 7000 Series",
        rangoFechas: '20/10/2022 - 27/10/2022',
        link: '/equipo-template',
        precioTotal: '3,500.00',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg']
    },
    {
        id: 2,
        nombreEquipo: "Montacargas Reach Slider 8000 Series",
        rangoFechas: '20/10/2022 - 27/10/2022',
        link: '/equipo-template',
        precioTotal: '4,800.00',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg']
    }
];

export const dummy_data_comentarios = [
    {
        id: 1,
        nombreCliente: "Francisco Martinez",
        calificacion: 5,
        titulo: 'Muy buen equipo',
        fecha: '23/09/2022',
        comentario: 'Esta en muy buenas condiciones y es 100% funcional',
        fotoPerfil: ''
    },
    {
        id: 2,
        nombreCliente: "Andrés Suarez",
        calificacion: 4,
        titulo: 'Bueno',
        fecha: '05/08/2022',
        comentario: 'La primera vez que lo renté todo bien, pero esta segunda vez intenté mover cosas mas pequeñas y no se ajusta bien',
        fotoPerfil: ''
    },
    {
        id: 3,
        nombreCliente: "Carlos Ramirez",
        calificacion: 4.5,
        titulo: 'Muy grande',
        fecha: '15/06/2022',
        comentario: 'El equipo es bueno pero requiere muchos servicios',
        fotoPerfil: ''
    }
];


export const dummy_data_direcciones = [
    {
        id: 1,
        alias: "Local Monterrey",
        calle: 'Av. Juárez',
        numero: '2654',
        colonia: 'Centro',
        estado: 2,
        estado_text: 'Nuevo león',
        ciudad: 4,
        ciudad_text: 'Monterrey',
        codigo_postal: '68956',
        es_principal: 1,
    },
    {
        id: 2,
        alias: "Local Apodaca",
        calle: 'Av. Carlos Salinas',
        numero: '127A',
        colonia: 'Los naranjos',
        estado: 2,
        estado_text: 'Nuevo león',
        ciudad: 5,
        ciudad_text: 'Apodaca',
        codigo_postal: '65233',
        es_principal: 0
    }
];

export const dummy_data_dias_bloqueados = [
    {fecha: '2022-09-28', tipo: 2}, 
    {fecha: '2022-09-29', tipo: 2}, 
    {fecha: '2022-09-30', tipo: 2}, 
    {fecha: '2022-10-01', tipo: 2},
    {fecha: '2022-10-02', tipo: 2},
    {fecha: '2022-10-03', tipo: 2},
    {fecha: '2022-10-16', tipo: 1},
    {fecha: '2022-10-17', tipo: 1},
    {fecha: '2022-10-18', tipo: 1},
    {fecha: '2022-10-19', tipo: 1},
    {fecha: '2022-10-20', tipo: 1},
    {fecha: '2022-10-30', tipo: 2},
    {fecha: '2022-10-31', tipo: 2},
];

export const dummy_data_rentas = [
    {
        id: 5,
        numeroRenta: "2022100059",
        numeroEquipos: '1 equipos rentados',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-10-21 - 2022-10-28',
        tipoCosto: 'Semanal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 2,690.00 MXN',
        estatus: 'Pendiente',
        fechaRenta: '',
        fechaRentaTerminada: '',
        fechaProspeccion: ''
    },
    {
        id: 4,
        numeroRenta: "2022100001",
        numeroEquipos: '3 equipos rentados',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-10-01 - 2022-10-03',
        tipoCosto: 'Diario',
        costoFlete: '$ 300.00 MXN',
        totalRenta: '$ 7,080.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-10-01 09:11',
        fechaRentaTerminada: '2022-10-03 20:41',
        fechaProspeccion: ''
    },
    {
        id: 3,
        numeroRenta: "2022090045",
        numeroEquipos: '1 equipo rentado',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-09-02 - 2022-09-17',
        tipoCosto: 'Quincenal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 6,350.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-09-02 10:15',
        fechaRentaTerminada: '2022-09-17 20:29',
        fechaProspeccion: ''
    },
    {
        id: 2,
        numeroRenta: "2022090045",
        numeroEquipos: '1 equipo rentado',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-09-02 - 2022-09-17',
        tipoCosto: 'Quincenal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 6,350.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-09-02 10:15',
        fechaRentaTerminada: '2022-09-17 20:29',
        fechaProspeccion: ''
    },
    {
        id: 1,
        numeroRenta: "2022090045",
        numeroEquipos: '1 equipo rentado',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-09-02 - 2022-09-17',
        tipoCosto: 'Quincenal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 6,350.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-09-02 10:15',
        fechaRentaTerminada: '2022-09-17 20:29',
        fechaProspeccion: ''
    },
    {
        id: 10,
        numeroRenta: "2022090045",
        numeroEquipos: '1 equipo rentado',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-09-02 - 2022-09-17',
        tipoCosto: 'Quincenal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 6,350.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-09-02 10:15',
        fechaRentaTerminada: '2022-09-17 20:29',
        fechaProspeccion: ''
    },
    {
        id: 11,
        numeroRenta: "2022090045",
        numeroEquipos: '1 equipo rentado',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-09-02 - 2022-09-17',
        tipoCosto: 'Quincenal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 6,350.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-09-02 10:15',
        fechaRentaTerminada: '2022-09-17 20:29',
        fechaProspeccion: ''
    },
    {
        id: 12,
        numeroRenta: "2022090045",
        numeroEquipos: '1 equipo rentado',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-09-02 - 2022-09-17',
        tipoCosto: 'Quincenal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 6,350.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-09-02 10:15',
        fechaRentaTerminada: '2022-09-17 20:29',
        fechaProspeccion: ''
    },
    {
        id: 13,
        numeroRenta: "2022090045",
        numeroEquipos: '1 equipo rentado',
        rentero: 'Montacargas Mty',
        rangoFechas: '2022-09-02 - 2022-09-17',
        tipoCosto: 'Quincenal',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 6,350.00 MXN',
        estatus: 'Terminada',
        fechaRenta: '2022-09-02 10:15',
        fechaRentaTerminada: '2022-09-17 20:29',
        fechaProspeccion: ''
    }
];

export const dummy_data_rentas_detalle = [
    {
        idRenta: 5,
        numeroRenta: "2022100059",
        nombreEquipo: 'Montacargas Reach Slider 7000 Series',
        rangoFechas: '2022-10-21 - 2022-10-28 (8 días)',
        tipoCosto: 'Semanal',
        costoDia: '$ 500.00 MXN',
        subTotal: '$ 4,000.00 MXN',
        costoFlete: '$ 350.00 MXN',
        totalRenta: '$ 4,350.00 MXN'
    }
];

export const dummy_data_operadores = [
    {
        id: 1,
        nombreOperador: 'Juan Marco Garza',
        categoria: 'Montacargas',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 5.0
    },
    {
        id: 2,
        nombreOperador: 'Carlos Moreno',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Excarvadoras de cualquier altura',
        precioDiario: '420.00',
        calificacion: 5.0
    },
    {
        id: 3,
        nombreOperador: 'Octavio Gutierrez',
        categoria: 'Montacargas',
        localidad: 'Apodaca, NL',
        descripcion: 'Dedicado a cualquier trabajo de constucción',
        precioDiario: '390.00',
        calificacion: 5.0
    },
    {
        id: 4,
        nombreOperador: 'Gonzalo Martinez',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 4.5
    },
    {
        id: 5,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 4.5
    },
    {
        id: 6,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 4.5
    },
    {
        id: 7,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Montacargas',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 4.0
    },
    {
        id: 8,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Montacargas',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 4.0
    },
    {
        id: 9,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 3.5
    },
    {
        id: 10,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 3.0
    },
    {
        id: 11,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 2.5
    },
    {
        id: 12,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 2.0
    },
    {
        id: 13,
        nombreOperador: 'Juan Marco Perez Garza',
        categoria: 'Excavadoras',
        localidad: 'Apodaca, NL',
        descripcion: 'Conductor certificado de montacargas...',
        precioDiario: '380.00',
        calificacion: 2.0
    }
];

export const dummy_data_operador = {
    id: 1,
    nombreOperador: 'Juan Marco Garza',
    localidad: 'Apodaca, NL',
    categoria: 'Montacargas',
    descripcion: 'Conductor certificado de montacargas... Operar las grúas, montacargas, o de otros equipos de elevación o desplazamiento. El control de equipos medidores o muestra para asegurar el correcto funcionamiento.',
    habilidades: [
        'Operar las grúas, montacargas, o de otros equipos de elevación o desplazamiento.',
        'El control de equipos medidores o muestra para asegurar el correcto funcionamiento.',
        'Mantener el equipo de material que se mueve en buenas condiciones de trabajo. Mover materiales, equipos o suministros.',
        'Descripción de habilidad 4',
        'Descripción de habilidad 5',
    ],
    precioDiario: '380.00',
    precioSemana: '2,600.00',
    precioQuincena: '5,000.00',
    precioMes: '9,200.00',
    telefono1: '8116598478',
    telefono2: '8126598956',
    correo: 'juan_garza@outlook.com',
    calificacion: 4.5
};

export const dummy_data_operadores_contactados = [
    {
        id: 1,
        nombreOperador: 'Juan Marco Garza',
        categoria: 'Montacargas',
        telefono: '816598946',
        correoElectronico: 'juan_garza@cosnstruccion.com',
        localidad: 'Apodaca, NL',
        calificacion: "Calificar"
    },
    {
        id: 2,
        nombreOperador: 'Carlos Moreno',
        categoria: 'Excavadoras',
        telefono: '8149499696',
        correoElectronico: 'cmoreno@google.com',
        localidad: 'Apodaca, NL',
        calificacion: "4.5"
    }
];


export const dummy_data_equipos_favoritos = [
    {
        id: 1,
        nombreEquipo: "Montacargas Reach Slider 7000 Series",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        precioSemana: 3500.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 2,
        nombreEquipo: "Montacargas Reach Slider 8000 Series",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran tres de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '8000 Series',
        horometro: '1205',
        precioDia: 980.00,
        precioSemana: 4500.00,
        descuento: 2,
        tieneDescuento: 'No',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 3,
        nombreEquipo: "Andamio Amarillo",
        categoriaId: 1,
        categoria: 'Andamio',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        precioSemana: 3500.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 1,
        nombreEsquema: 'Renta Segura',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 7,
        nombreEquipo: "Montacargas Reach Slider 7000 Series",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        precioSemana: 3500.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        esquema: 2,
        nombreEsquema: 'Renta Abierta',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 8,
        nombreEquipo: "Montacargas Reach Slider 8000 Series",
        nombreEmpresa: "Montacargas Mty",
        categoriaId: 1,
        categoria: 'Montecargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran tres de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '8000 Series',
        horometro: '1205',
        precioDia: 980.00,
        precioSemana: 4500.00,
        descuento: 2,
        tieneDescuento: 'No',
        estatus: true,
        esquema: 2,
        nombreEsquema: 'Renta Abierta',
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
];

export const dummy_data_rentas_en_proceso = [
    {
        id: 5,
        numeroRenta: "2022100059",
        numeroEquipos: 2,
        rentero: 'Montacargas Mty',
        direccionRentero: "xicotencatl Número 1, Colonia centro,  Monterrey",
        precioTotal: 13384.00,
        precioFlete: 500.00,
        estado_renta: 7,
        fechaInicio: "2023-05-20",
        fechaFin: "2023-05-27",
        totalDias: 7,
        tipoCosto: "Semanal",
        equipos: [
                {
                    id: 0,
                    equipo_id: 3,
                    datesRange: {
                        startDate: "2023-05-19T06:00:00.000Z",
                        endDate: "2023-05-25T06:00:00.000Z",
                        key: "selection"
                    },
                    fechaInicio: "2023-05-20",
                    fechaFin: "2023-05-27",
                    totalDias: 7,
                    tipoCosto: "Semanal",
                    costoDia: "1762.00",
                    subtotal: "12334.00",
                    nombre: "Montacargas",
                    precioDia: 12345,
                    precioMes: 1233,
                    precioOperadorDia: 0,
                    precioQuincena: 12334,
                    precioSemana: 12334,
                    rentero: {
                        nombreEmpresa: "Montacargas Mty",
                        calificacionGeneral: 0,
                        id__normalized: 2,
                        usuario: {
                            email: "israeltrevino@gmail.com",
                            phoneNumber: "8116605169"
                        },
                        direccion: {
                            direccionCompleta: "xicotencatl Número 1, Colonia centro,  Monterrey",
                            latitud: 0,
                            longitud: 0
                        }
                    },
                    serviciosEquipos: null,
                    imagenEquipos: [
                        {
                            id__normalized: 4,
                            url: "https://test-poteis.s3.us-east-2.amazonaws.com/Equipos/3/DALL_E_2023-01-31_10.39.36-removebg-preview.png",
                            isPrincipal: true,
                            local: {
                                childImageSharp: {
                                    gatsbyImageData: {
                                        layout: "constrained",
                                        backgroundColor: "#080808",
                                        images: {
                                            fallback: {
                                                "src": "/static/56c5727ede232cbc5106e1c02af1fd95/82c11/DALL_E_2023-01-31_10.39.36-removebg-preview.png",
                                                "srcSet": "/static/56c5727ede232cbc5106e1c02af1fd95/2fd20/DALL_E_2023-01-31_10.39.36-removebg-preview.png 125w,\n/static/56c5727ede232cbc5106e1c02af1fd95/de391/DALL_E_2023-01-31_10.39.36-removebg-preview.png 250w,\n/static/56c5727ede232cbc5106e1c02af1fd95/82c11/DALL_E_2023-01-31_10.39.36-removebg-preview.png 500w",
                                                "sizes": "(min-width: 500px) 500px, 100vw"
                                            },
                                            sources: [
                                                {
                                                    "srcSet": "/static/56c5727ede232cbc5106e1c02af1fd95/d66e1/DALL_E_2023-01-31_10.39.36-removebg-preview.webp 125w,\n/static/56c5727ede232cbc5106e1c02af1fd95/e7160/DALL_E_2023-01-31_10.39.36-removebg-preview.webp 250w,\n/static/56c5727ede232cbc5106e1c02af1fd95/5f169/DALL_E_2023-01-31_10.39.36-removebg-preview.webp 500w",
                                                    "type": "image/webp",
                                                    "sizes": "(min-width: 500px) 500px, 100vw"
                                                }
                                            ]
                                        },
                                        width: 500,
                                        height: 500
                                    }
                                }
                            }
                        },
                        {
                            id__normalized: 5,
                            url: "https://test-poteis.s3.us-east-2.amazonaws.com/Equipos/3/sazonador_umami-removebg-preview.png",
                            isPrincipal: false,
                            local: {
                                childImageSharp: {
                                    gatsbyImageData: {
                                        layout: "constrained",
                                        backgroundColor: "#080808",
                                        images: {
                                            fallback: {
                                                "src": "/static/4d135b1b84d60c240fe5aa6091631efb/25bd2/sazonador_umami-removebg-preview.png",
                                                "srcSet": "/static/4d135b1b84d60c240fe5aa6091631efb/47621/sazonador_umami-removebg-preview.png 119w,\n/static/4d135b1b84d60c240fe5aa6091631efb/c0517/sazonador_umami-removebg-preview.png 237w,\n/static/4d135b1b84d60c240fe5aa6091631efb/25bd2/sazonador_umami-removebg-preview.png 474w",
                                                "sizes": "(min-width: 474px) 474px, 100vw"
                                            },
                                            sources: [
                                                {
                                                    "srcSet": "/static/4d135b1b84d60c240fe5aa6091631efb/022c3/sazonador_umami-removebg-preview.webp 119w,\n/static/4d135b1b84d60c240fe5aa6091631efb/b8446/sazonador_umami-removebg-preview.webp 237w,\n/static/4d135b1b84d60c240fe5aa6091631efb/e8eea/sazonador_umami-removebg-preview.webp 474w",
                                                    "type": "image/webp",
                                                    "sizes": "(min-width: 474px) 474px, 100vw"
                                                }
                                            ]
                                        },
                                        width: 474,
                                        height: 287
                                    }
                                }
                            }
                        }
                    ],
                    categoriaEquipo: {
                        nombre: "Maquinaria Pesada de Construccion",
                        id__normalized: 1
                    },
                    aditamentosEquipos: [],
                    id__normalized: 3,
                    modelo: "x1234",
                    esquema: {
                        nombre: "Renta Segura",
                        id__normalized: 2
                    },
                    descripcion: "Test descripcion",
                    disponible: true,
                    horometro: "5"
                },
                {
                    id: 0,
                    equipo_id: 4,
                    datesRange: {
                        startDate: "2023-05-19T06:00:00.000Z",
                        endDate: "2023-05-25T06:00:00.000Z",
                        key: "selection"
                    },
                    fechaInicio: "2023-05-20",
                    fechaFin: "2023-05-27",
                    totalDias: 7,
                    tipoCosto: "Semanal",
                    costoDia: "1762.00",
                    subtotal: "1050.00",
                    nombre: "Montacargas 2",
                    precioDia: 210,
                    precioMes: 3800,
                    precioOperadorDia: 0,
                    precioQuincena: 2000,
                    precioSemana: 1050,
                    rentero: {
                        nombreEmpresa: "Montacargas Mty",
                        calificacionGeneral: 0,
                        id__normalized: 2,
                        usuario: {
                            email: "israeltrevino@gmail.com",
                            phoneNumber: "8116605169"
                        },
                        direccion: {
                            direccionCompleta: "xicotencatl Número 1, Colonia centro,  Monterrey",
                            latitud: 0,
                            longitud: 0
                        }
                    },
                    serviciosEquipos: null,
                    imagenEquipos: [
                        {
                            id__normalized: 4,
                            url: "https://test-poteis.s3.us-east-2.amazonaws.com/Equipos/3/DALL_E_2023-01-31_10.39.36-removebg-preview.png",
                            isPrincipal: true,
                            local: {
                                childImageSharp: {
                                    gatsbyImageData: {
                                        layout: "constrained",
                                        backgroundColor: "#080808",
                                        images: {
                                            fallback: {
                                                "src": "/static/56c5727ede232cbc5106e1c02af1fd95/82c11/DALL_E_2023-01-31_10.39.36-removebg-preview.png",
                                                "srcSet": "/static/56c5727ede232cbc5106e1c02af1fd95/2fd20/DALL_E_2023-01-31_10.39.36-removebg-preview.png 125w,\n/static/56c5727ede232cbc5106e1c02af1fd95/de391/DALL_E_2023-01-31_10.39.36-removebg-preview.png 250w,\n/static/56c5727ede232cbc5106e1c02af1fd95/82c11/DALL_E_2023-01-31_10.39.36-removebg-preview.png 500w",
                                                "sizes": "(min-width: 500px) 500px, 100vw"
                                            },
                                            sources: [
                                                {
                                                    "srcSet": "/static/56c5727ede232cbc5106e1c02af1fd95/d66e1/DALL_E_2023-01-31_10.39.36-removebg-preview.webp 125w,\n/static/56c5727ede232cbc5106e1c02af1fd95/e7160/DALL_E_2023-01-31_10.39.36-removebg-preview.webp 250w,\n/static/56c5727ede232cbc5106e1c02af1fd95/5f169/DALL_E_2023-01-31_10.39.36-removebg-preview.webp 500w",
                                                    "type": "image/webp",
                                                    "sizes": "(min-width: 500px) 500px, 100vw"
                                                }
                                            ]
                                        },
                                        width: 500,
                                        height: 500
                                    }
                                }
                            }
                        },
                        {
                            id__normalized: 5,
                            url: "https://test-poteis.s3.us-east-2.amazonaws.com/Equipos/3/sazonador_umami-removebg-preview.png",
                            isPrincipal: false,
                            local: {
                                childImageSharp: {
                                    gatsbyImageData: {
                                        layout: "constrained",
                                        backgroundColor: "#080808",
                                        images: {
                                            fallback: {
                                                "src": "/static/4d135b1b84d60c240fe5aa6091631efb/25bd2/sazonador_umami-removebg-preview.png",
                                                "srcSet": "/static/4d135b1b84d60c240fe5aa6091631efb/47621/sazonador_umami-removebg-preview.png 119w,\n/static/4d135b1b84d60c240fe5aa6091631efb/c0517/sazonador_umami-removebg-preview.png 237w,\n/static/4d135b1b84d60c240fe5aa6091631efb/25bd2/sazonador_umami-removebg-preview.png 474w",
                                                "sizes": "(min-width: 474px) 474px, 100vw"
                                            },
                                            sources: [
                                                {
                                                    "srcSet": "/static/4d135b1b84d60c240fe5aa6091631efb/022c3/sazonador_umami-removebg-preview.webp 119w,\n/static/4d135b1b84d60c240fe5aa6091631efb/b8446/sazonador_umami-removebg-preview.webp 237w,\n/static/4d135b1b84d60c240fe5aa6091631efb/e8eea/sazonador_umami-removebg-preview.webp 474w",
                                                    "type": "image/webp",
                                                    "sizes": "(min-width: 474px) 474px, 100vw"
                                                }
                                            ]
                                        },
                                        width: 474,
                                        height: 287
                                    }
                                }
                            }
                        }
                    ],
                    categoriaEquipo: {
                        nombre: "Maquinaria Pesada de Construccion",
                        id__normalized: 1
                    },
                    aditamentosEquipos: [],
                    id__normalized: 3,
                    modelo: "x1234",
                    esquema: {
                        nombre: "Renta Segura",
                        id__normalized: 2
                    },
                    descripcion: "Test descripcion",
                    disponible: true,
                    horometro: "5"
                }
        ]
    }
];